<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="套餐名称">
            <a-input
              v-decorator="['name_or_base_product_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="套餐类型">
            <a-select
              v-decorator="['product_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in productTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="有效性">
            <a-select
              v-decorator="['effective', { initialValue: '1' }]"
              allow-clear
            >
              <a-select-option key="1" value="1">
                有效
              </a-select-option>
              <a-select-option key="0" value="0">
                无效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="是否零售">
            <a-select
              v-decorator="['is_allow_retail']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                是
              </a-select-option>
              <a-select-option key="0" value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-show="expand">
          <a-form-item label="是否自订">
            <a-select
              v-decorator="['is_allow_self_subscribe']"
              allow-clear
            >
              <a-select-option key="1" value="1">
                是
              </a-select-option>
              <a-select-option key="0" value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findCarrierTypeOptions, findUserBusinessTypeOptions, findProductTypeOptions } from '@/api/common_options'

export default {
  name: 'SearchProduct',
  data() {
    return {
      expand: false,
      roleOptions: [],
      carrierTypeOptions: findCarrierTypeOptions(),
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      productTypeOptions: findProductTypeOptions(),
      form: this.$form.createForm(this, { name: 'agents_product_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
