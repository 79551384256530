<template>
  <div>
    <search-product @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showImportModal"
          v-if="isShowMenu('agents_products/import')"
        >导入通用套餐
        </a-button>

        <update-product-effective
          v-if="isShowMenu('agents_products/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isShowMenu('agents_products/show')">
          <a @click="showInfoModal(record.id)">{{ name }}</a>
        </div>

        <div v-else> {{ name }} </div>
      </div>

      <div slot="retail_price" slot-scope="retail_price">
        <span>{{ formatFloatDisplay(retail_price) }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.product_type_slug, record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 导入模态框 -->
    <import-agents-product
      v-if="isShowImportModal"
      :agent-id="agentId"
      :visible.sync="isShowImportModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-agents-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-agents-product-addon
      v-if="isShowEditAddonModal"
      :visible.sync="isShowEditAddonModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agents-product
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="AgentsProduct"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentsProducts } from '@/api/agents_product'
import SearchProduct from '@/views/agents_products/Search'
import Pagination from '@/components/Pagination'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'AgentsProductList',
  components: {
    Pagination,
    SearchProduct,
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    ImportAgentsProduct: () => import('@/views/agents_products/import/index'),
    EditAgentsProduct: () => import('@/views/agents_products/Edit'),
    EditAgentsProductAddon: () => import('@/views/agents_products/AddonEdit'),
    ShowAgentsProduct: () => import('@/views/agents_products/Show'),
    UpdateProductEffective: () => import('@/views/agents_products/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowImportModal: false, // 是否显示导入表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowEditAddonModal: false, // 是否显示编辑表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      editingId: 0, // 正在编辑的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: { effective: '1' },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '套餐编号',
          dataIndex: 'code',
          fixed: 'left'
        },
        {
          title: '套餐名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          fixed: 'left'
        },
        {
          title: '基础套餐名称',
          dataIndex: 'base_product_name',
          fixed: 'left'
        },
        {
          title: '套餐类型',
          dataIndex: 'product_type'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '流量属性',
          dataIndex: 'traffic_property'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat
        },
        {
          title: '零售价格(元)',
          dataIndex: 'retail_price',
          scopedSlots: { customRender: 'retail_price' }
        },
        {
          title: '是否零售',
          dataIndex: 'is_allow_retail'
        },
        {
          title: '是否自订',
          dataIndex: 'is_allow_self_subscribe'
        },
        {
          title: '是否可超池',
          dataIndex: 'is_allow_exceed_pool'
        },
        {
          title: '套餐有效性',
          dataIndex: 'effective'
        },
        {
          title: '关联卡数(不含销卡)',
          dataIndex: 'card_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'card_count' ? this.sort.sort_order : false
        },
        {
          title: '导入时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right'
        }
      ]
    },

    agentId() {
      return parseInt(this.$route.params.id)
    },

    isHasEditPermission() {
      return hasPermission('agents_products/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    formatFloatDisplay(value) {
      if (value === -1) {
        return '-'
      }
      return formatFloat(value)
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showImportModal() {
      this.isShowImportModal = true
    },

    showEditModal(productTypeSlug, recordId) {
      if (productTypeSlug === 'common') {
        this.isShowEditModal = true
      } else {
        this.isShowEditAddonModal = true
      }

      this.editingId = recordId
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAgentsProducts(Object.assign({ agent_id: this.agentId }, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    }
  }
}
</script>
