import request from '@/utils/request'

// 查询客户套餐
export function findAgentsProducts(params) {
  return request({
    url: `/agents_products`,
    method: 'get',
    params: params
  })
}

// 客户套餐选项数据
export function findAgentsProductsOptions(params) {
  return request({
    url: `/agents_products/options`,
    method: 'get',
    params: params
  })
}

// 导入客户通用套餐
export function importAgentsProducts(data) {
  return request({
    url: `/agents_products/import`,
    method: 'post',
    data
  })
}

// 查询单个客户套餐表单值
export function findAgentsProductForm(id) {
  return request({
    url: `/agents_products/${id}/form`,
    method: 'get'
  })
}

// 更新客户套餐
export function updateAgentsProduct(id, data) {
  return request({
    url: `/agents_products/${id}`,
    method: 'put',
    data
  })
}

// 校验套餐价格
export function checkAgentsProductPrices(id, data) {
  return request({
    url: `/agents_products/${id}/check_prices`,
    method: 'post',
    data
  })
}

// 批量更新客户套餐有效性
export function batchUpdateAgentsProductsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/agents_products/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询单个套餐
export function findAgentsProduct(id) {
  return request({
    url: `/agents_products/${id}`,
    method: 'get'
  })
}
